/* You can add global styles to this file, and also import other style files */
@import './styles/main';

* {
  box-sizing: border-box;
}

.page--title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.text--wrap {
  word-break: break-all;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-flex-center {
  justify-content: center;
}

@include mobile {
  .page--title {
    font-size: 21px;
  }
}

.text--wrap {
  word-break: break-all;
}

.legal__link {
  color: var(--cds-secondary-color);
  text-decoration: underline;
}

// TODO: Mover esto al cds pero con un mejor control de z-index
#_CDS_LOADING_PORTAL_ID,
#_CDS_ALERT_PORTAL_ID {
  position: sticky;
  z-index: 99999;
}

.sortable {
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    border-style: solid;
    border-width: 1px 1px 0 0;
    display: inline-block;
    height: 4px;
    width: 4px;
    left: 3px;
    position: absolute;
    vertical-align: top;
  }
  &::before {
    top: 15px;
    transform: rotate(-45deg);
  }
  &::after {
    top: 20px;
    transform: rotate(135deg);
  }
  &.asc {
    &::before {
      border-width: 1.5px 1.5px 0 0;
      border-color: #27282b;
    }
  }
  &.desc {
    &::after {
      border-width: 1.5px 1.5px 0 0;
      border-color: #27282b;
    }
  }
}
